import React, { useState } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import SectionHeader from "../components/section-header/section-header";

import "./quienes-somos.scss";
import { Link } from "gatsby";
import ProfileCard from "../components/profile-card/profile-card";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { SloganFlipper } from "../components/slogan/slogan-flipper";

export default function QuienesSomos() {
    const [status, setStatus] = useState("");

    return (
      <React.Fragment>
        <SEO title="Quienes somos" />
        <Layout pageId="about-page">
          <Helmet title={`negligenciasdeabogados.net - Autovaloración`} />
          <h1>Quiénes somos</h1>

          {/* Viene siendo habitual, solo hay que mirar las búsquedas más
                    frecuentes en Google, que algunos clientes piensen: mi
                    abogado no me informa, mi abogado de oficio no me atiende,
                    mi abogado no me da copia de la demanda, mi abogado no me da
                    la factura, mi abogado me ignora, mi abogado me ha estafado,
                    mi abogado no me da la sentencia, mi abogado me engaña, ¿Qué
                    pasa si no se le paga al abogado? ¿Qué pasa si un abogado no
                    se presenta a juicio? ¿Qué es mala praxis en abogacía? Cómo
                    se inicia una reclamación contra abogado, plazo para
                    reclamar por negligencia abogado, cómo denunciar a un
                    abogado, que hacer si mi abogado no responde, mi abogado se
                    ha vendido, sirve de algo poner queja en el Colegio de
                    Abogados, cómo poner una queja a un abogado, ¿Cuánto se
                    puede ganar en un juicio de mala praxis? Sentencias contra
                    abogados por negligencia, indemnización negligencia abogado,
                    Pérdida de oportunidad negligencia abogado. Por otra parte,
                    se da la circunstancia de que esas búsquedas en google
                    ofrecen unos resultados que poco o nada responden las
                    preguntas formuladas pues lo que suele aparecer son
                    artículos jurídicos, sentencias y algún despacho de abogados
                    que ofrece asesoramiento al respecto pero no de manera
                    específica sino dentro de una más amplia cartera de
                    servicios dirigidas a otro tipo de clientes. En definitiva,
                    el cliente se encuentra en internet con escasos resultados a
                    su búsqueda de información y asesoramiento todo ello,
                    además, en un leguaje muy difícil de comprender y la casi
                    imposibilidad de obtener una mínima orientación inicial para
                    saber cómo actuar y lo que le puede esperar, además del
                    temor, infundado hemos de decir en la mayoría de los casos,
                    a que por parte de su Abogado puedan tomarse medidas en su
                    contra como represalia por la presunta falta de confianza
                    que le demuestra el cliente al solicitarle información,
                    documentos e incluso acudir a su Colegio Profesional. */}

          <div className="about-container">
            {/* <h2 className="first">Nuestros expertos</h2>
                    <div className="experts grid grid--desktop-2cols grid--smartphone-1cols">
                        <ProfileCard name="Juan Jose Gonzalez Ferrer" image="/images/juanjosegonzalez.jpg" />
                        <ProfileCard name="Tatarabu" image="/images/tatarabu.jpeg" />
                    </div> */}

            <Fade left>
              <h2 className="first">Iniciativa pionera</h2>
              <p>
                Conscientes de la problemática y dificultad de los clientes
                perjudicados por los errores y omisiones de sus abogados,{" "}
                <strong>
                  <Link to="/">negligenciasdeabogados.net</Link>
                </strong>{" "}
                surge como <strong>iniciativa pionera en España</strong>{" "}
                constituyéndose en el medio más idóneo para encontrar soluciones
                factibles, tanto por el conocimiento y experiencia que atesoran{" "}
                <strong>
                  nuestros expertos en Responsabilidad Civil Profesional y
                  aseguramiento de los Abogados
                </strong>{" "}
                como por un modelo de negocio enfocado a ofrecer{" "}
                <Link to="/nuestros-servicios/">nuestros servicios</Link>{" "}
                presididos por la <strong>empatía y transparencia</strong>,
                rapidez y precios competitivos.
              </p>
            </Fade>

            <Fade left>
              <h2>Nuestro propósito</h2>
              <p>
                Nuestro propósito es cubrir la necesidad cada vez mayor de
                encontrar un <strong>asesoramiento experto</strong> que sirva
                como guía y ayuda a los clientes perjudicados de las
                negligencias profesionales de los Abogados.
              </p>
              <p>
                Además del <strong>perjuicio económico</strong> que por las
                negligencias se ocasiona al cliente, también se le genera un{" "}
                <strong>daño emocional</strong> por la pérdida de confianza en
                su Abogado y en la propia administración de Justicia, con el
                añadido de la dificultad en obtener una idea precisa de su
                situación y pasos a seguir dadas las dificultades y trabas con
                que se topan los clientes, por ello el objetivo final de{" "}
                <strong>
                  <Link to="/">negligenciasdeabogados.net</Link>
                </strong>{" "}
                , además de asesorar a nuestros clientes para que sepan{" "}
                <strong>si su reclamación es viable</strong> y merece la pena,
                es el de intentar encauzar el problema y resolverlo cuanto antes
                de la manera más satisfactoria.
              </p>
            </Fade>

            <Fade left>
              <h2>Modelo de negocio</h2>
              <p>
                Apostamos por un novedoso y amplio abanico de posibilidades a
                través de nuestros servicios gratuitos y premiums enfocados a
                ti, como cliente de un abogado, que te sientes insatisfecho e
                incluso perjudicado, de manera que:
              </p>
              <ul className="business-model">
                <li key="bm-0">
                  <strong>Damos respuesta a todas las dudas y problemas</strong>
                  que se te puedan plantear.
                </li>
                <li key="bm-1">
                  <strong>Ofrecemos soluciones gratuitas</strong> o a precios
                  muy competitivos, según el servicio que necesites.
                </li>
                <li key="bm-2">
                  <strong>Ahorramos el tiempo y el desgaste</strong> que te
                  supone tener que acudir inicialmente a otro abogado, al
                  Colegio de Abogados, al Juzgado etc.
                </li>
                <li key="bm-3">
                  <strong>
                    La decisión e iniciativa de los pasos a dar es
                    exclusivamente tuya
                  </strong>
                  : esperar, contratar a otro abogado para sustituir o demandar
                  al anterior, presentar queja ente el Colegio de Abogados que
                  corresponda, reclamar directamente al abogado o a su compañía
                  de seguros, etc..
                </li>
                <li key="bm-4">
                  <strong>Garantizamos confidencialidad total</strong>, nuestros
                  expertos no contactarán en ningún caso con tu abogado.{" "}
                </li>
              </ul>
            </Fade>

            <SloganFlipper></SloganFlipper>
          </div>
        </Layout>
      </React.Fragment>
    )
}
